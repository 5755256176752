<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { mapState } from "vuex";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Dashboard: "Painel",
      "Referral link": "Link de indicação",
      "To activate your indication link you need to be active in a board.": "Para ativar o seu link de indicação você precisa estar ativo em um tabuleiro.",
      "You haven't invited 2 people to donate yet and you won't be able to progress after receiving all four donations from the bronze board.": "Você ainda não convidou 2 pessoas para doar e não poderá progredir após receber as 4 doações do tabuleiro bronze.",
      "My Boards": "Meus Tabuleiros",
      "My Sponsored": "Meus Indicados",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
      "Change Data": "Alterar Dados",
      "Smart Search": "Busca Inteligente",
    },
    es: {
      Dashboard: "Panel",
      "Referral link": "Link de patrocineo",
      "My Boards": "Mis Tableros",
      "To activate your indication link you need to be active in a board.": "Para activar su enlace de indicación, debe estar activo en una placa.",
      "You haven't invited 2 people to donate yet and you won't be able to progress after receiving all four donations from the bronze board.": "Todavía no ha invitado a 2 personas a donar y no podrá progresar después de recibir las 4 donaciones de la tablero de bronce.",
      "My Sponsored": "Meus Indicados",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
      "Change Data": "Alterar Dados",
      "Smart Search": "Busca Inteligente",
    },
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    monitorWidth: function () {
      var width = screen.width;
      return width;
    }
  },
  data() {
    return {
      informatives: {
        modal: false,
        list: null,
      },

      referrallink: null,

      total: {
        sponsored: "0",
        donations: "US$ -,--",
      },

      monthly: "inactive",

      statData: null,

      donations: {
        pendings10: null,
        pendings100: null,
        pendings1000: null,
        pendingsperfect: null,
      },

      pix: {
        type: null,
        key: null,
      },
    };
  },
  methods: {
    getInformatives() {
      api.get("informatives").then((response) => {
        if (response.data.status == "success") {
          this.informatives.list = response.data.list;

          if (this.informatives.list.length > 0 && this.informatives.list[0]) {
            this.informatives.modal = true;
          }
        }
      });
    },
    getReferralLink() {
      api.get("user/referrallink").then((response) => {
        this.referrallink = response.data.status;
      });
    },
    getTotalSponsored() {
      api.get("boards/total/sponsored").then((response) => {
        if (response.data.status == "success") {
          this.total.sponsored = response.data.total.toString();
          this.getStatData();
        }
      });
    },
    getTotalDonation() {
      api.get("boards/total/donations").then((response) => {
        if (response.data.status == "success") {
          this.total.donations = this.$options.filters.currency(
            response.data.total
          );
          this.getStatData();
        }
      });
    },
    getStatData() {
      this.statData = [
        {
          title: "Username",
          value: this.account.user.username,
        },
        {
          title: "Sponsored",
          value: this.total.sponsored,
        },
        {
          title: "Donation Received",
          value: this.total.donations,
        },
      ];
    },
    getDonationsPendings10() {
      api.get("station10/boards/donations/pendings").then((response) => {
        if (response.data.status == "success") {
          this.donations.pendings10 = response.data.list;
        }
      });
    },
    getDonationsPendings100() {
      api.get("station100/boards/donations/pendings").then((response) => {
        if (response.data.status == "success") {
          this.donations.pendings100 = response.data.list;
        }
      });
    },
    getDonationsPendings1000() {
      api.get("station1000/boards/donations/pendings").then((response) => {
        if (response.data.status == "success") {
          this.donations.pendings1000 = response.data.list;
        }
      });
    },
    getDonationsPendingsPerfect() {
      api.get("stationperfect/boards/donations/pendings").then((response) => {
        if (response.data.status == "success") {
          this.donations.pendingsperfect = response.data.list;
        }
      });
    },
    getPix() {
      api.get("user/pix").then((response) => {
        if (response.data.status == "success") {
          this.pix.type = response.data.type;
          this.pix.key = response.data.key;
        }
      });
    },
  },
  mounted() {
    this.getInformatives();
    this.getReferralLink();
    this.getTotalSponsored();
    this.getTotalDonation();
    this.getStatData();
    this.getDonationsPendings10();
    this.getDonationsPendings100();
    this.getDonationsPendings1000();
    this.getDonationsPendingsPerfect();
    this.getPix();
  },
};
</script>

<template>
  <Layout>
    <b-modal v-if="informatives.list && informatives.list[0]" v-model="informatives.modal" :title="informatives.list[0].title" centered size="xl">
      <template v-if="monitorWidth < 600">
        <div v-if="informatives.list[0].image.mobile" class="mb-3">
          <template v-if="informatives.list[0].url">
            <a target="_blank" :href="informatives.list[0].url"><img class="w-100 rounded" :src="informatives.list[0].image.mobile" :alt="informatives.list[0].title" /></a>
          </template>
          <template v-else>
            <img class="w-100 rounded" :src="informatives.list[0].image.mobile" :alt="informatives.list[0].title" />
          </template>
        </div>
      </template>
      <template v-else>
        <div v-if="informatives.list[0].image.desktop" class="mb-3">
          <template v-if="informatives.list[0].url">
            <a target="_blank" :href="informatives.list[0].url"><img class="w-100 rounded" :src="informatives.list[0].image.desktop" :alt="informatives.list[0].title" /></a>
          </template>
          <template v-else>
            <img class="w-100 rounded" :src="informatives.list[0].image.desktop" :alt="informatives.list[0].title" />
          </template>
        </div>
      </template>
      <div v-html="informatives.list[0].content"></div>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>

    <Banners></Banners>

    <template v-if="referrallink && referrallink == 'inactive'">
      <router-link class="bg-soft-danger p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/boards'">
        <i class="lnr lnr-warning text-dark" style="font-size: 30px"></i>
        <div class="px-3 flex-fill">
          <h6 class="m-0 font-size-15">
            {{ t("To activate your indication link you need to be active in a board.") }}
          </h6>
        </div>
      </router-link>
    </template>

    <template v-if="referrallink && referrallink == 'active' && this.total.sponsored < 2">
      <router-link class="bg-soft-danger p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/network/sponsored'">
        <i class="lnr lnr-warning text-dark" style="font-size: 30px"></i>
        <div class="px-3 flex-fill">
          <h6 class="m-0 font-size-15">
            {{ t("You haven't invited 2 people to donate yet and you won't be able to progress after receiving all four donations from the bronze board.") }}
          </h6>
        </div>
      </router-link>
    </template>

    <template v-if="donations.pendings10 && donations.pendings10.length > 0">
      <div v-for="(row, index) in donations.pendings10" :key="index">
        <template v-if="row.receiver">
          <router-link class="bg-soft-info p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/station10/' + row.id">
            <i class="lnr lnr-star text-dark" style="font-size: 30px"></i>
            <div class="px-3 flex-fill pt-1">
              <h6 class="m-0 font-size-15 text-">
                Faça a doação para <strong>"{{ row.receiver.username }}"</strong> no valor de <strong>{{ row.value | currency }}</strong> em seu tabuleiro <strong>{{ row.type }} na Estação
                  10</strong>.
              </h6>
            </div>
          </router-link>
        </template>
      </div>
    </template>

    <template v-if="donations.pendings100 && donations.pendings100.length > 0">
      <div v-for="(row, index) in donations.pendings100" :key="index">
        <template v-if="row.receiver">
          <router-link class="bg-soft-info p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/station100/' + row.id">
            <i class="lnr lnr-star text-dark" style="font-size: 30px"></i>
            <div class="px-3 flex-fill pt-1">
              <h6 class="m-0 font-size-15 text-">
                Faça a doação para <strong>"{{ row.receiver.username }}"</strong> no valor de <strong>{{ row.value | currency }}</strong> em seu tabuleiro <strong>{{ row.type }} na Estação
                  100</strong>.
              </h6>
            </div>
          </router-link>
        </template>
      </div>
    </template>

    <template v-if="donations.pendings1000 && donations.pendings1000.length > 0">
      <div v-for="(row, index) in donations.pendings1000" :key="index">
        <template v-if="row.receiver">
          <router-link class="bg-soft-info p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/station100/' + row.id">
            <i class="lnr lnr-star text-dark" style="font-size: 30px"></i>
            <div class="px-3 flex-fill pt-1">
              <h6 class="m-0 font-size-15 text-">
                Faça a doação para <strong>"{{ row.receiver.username }}"</strong> no valor de <strong>{{ row.value | currency }}</strong> em seu tabuleiro <strong>{{ row.type }} na Estação
                  1000</strong>.
              </h6>
            </div>
          </router-link>
        </template>
      </div>
    </template>

    <template v-if="pix && !pix.key">
      <router-link class="d-none bg-soft-warning p-3 mb-3 rounded d-flex align-items-center" tag="a" :to="'/account/pix'">
        <i class="lnr lnr-rocket text-dark" style="font-size: 30px"></i>
        <div class="px-3 flex-fill">
          <h6 class="m-0 font-size-15">
            <strong>{{ t("Cadastre sua chave PIX!") }}</strong><br>
            {{ t("Para agilizar o recebimento das suas doações cadastre agora mesmo a sua chave PIX.") }}
          </h6>
        </div>
      </router-link>
    </template>

    <div class="card">
      <div class="card-body py-2 d-flex align-items-center">
        <div class="mt-1">
          <i class="bx bx-link font-size-20 mr-1"></i>
        </div>
        <h5 class="m-0 align-items-center">
          {{ t("Referral link") }}
        </h5>
        <div class="mt-1 ml-3">
          <ShareNetwork network="whatsapp" url="" :title="'https://be.blessyoou.com/' + account.user.username">
            <i class="bx bxl-whatsapp font-size-24 text-dark p-0"></i>
          </ShareNetwork>
        </div>
        <div class="mt-1 ml-2">
          <button class="btn btn-link p-0 m-0" v-clipboard:copy="'https://be.blessyoou.com/' + account.user.username
            " v-on:click="$noty.success('The link was copied!')">
            <i class="bx bx-copy font-size-20 text-dark"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4 col-lg-4 col-xl-4">
        <Stat :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="d-block d-sm-none">
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/station10">
        <i class="lnr lnr-layers font-size-24 mr-2"></i>
        <div>{{ t("Estação 10") }}</div>
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/station100">
        <i class="lnr lnr-layers font-size-24 mr-2"></i>
        <div>{{ t("Estação 100") }}</div>
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/station1000">
        <i class="lnr lnr-layers font-size-24 mr-2"></i>
        <div>{{ t("Estação 1000") }}</div>
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/stationperfect">
        <i class="lnr lnr-layers font-size-24 mr-2"></i>
        <div>{{ t("Estação Perfect") }}</div>
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/network/sponsored">
        <i class="lnr lnr-user font-size-24 mr-2"></i>
        {{ t("My Sponsored") }}
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/account">
        <i class="lnr lnr-cog font-size-24 mr-2"></i>
        {{ t("Change Data") }}
      </router-link>
      <router-link class="btn btn-default btn-block btn-lg py-3 d-flex align-items-center justify-content-center mb-3" tag="a" to="/network/team">
        <i class="lnr lnr-user font-size-24 mr-2"></i>
        {{ t("Smart Search") }}
      </router-link>
    </div>
  </Layout>
</template>
